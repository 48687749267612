.warehouse-form-inline {
  display: flex;
  flex-direction: row;
  align-items: center;

  input.form-control {
    max-width: 100px;
  }

  &-unit {
    padding: 6px 12px;
  }
  &-measure-select {
    margin-left: 16px;
    width: 100px;
  }
}
