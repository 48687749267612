.search-func-container {
  position: fixed;
  z-index: 100;
  width: 400px;
  background-color: aliceblue;
  left: 50%;
  top: 30%;
  transform: translate(-50%, 0);
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.search-func-invisible {
  display: none;
}

.search-func-recommend {
  position: absolute;
  background-color: whitesmoke;
  width: 380px;
  border: silver solid 1px;
  border-top: none;
  max-height: 500px;
}

.search-func-recommend ul {
  padding: 0px;
  margin-bottom: 0px;
}

.search-func-recommend-container {
  overflow: auto;
  max-height: 500px;
}

.heading-recommend {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 0.5px solid black;
  padding: 0px 10px;
}

.search-func-recommend li {
  list-style: none;
  border-bottom: solid 1px silver;
  padding: 5px 15px;
}

.search-func-recommend li:last-child {
  border-bottom: none;
}

.search-func-recommend li:hover {
  background-color: slategray;
  color: #fff;
}

.search-func-recommend a {
  text-decoration: none;
  color: inherit;
}
